import React, { Component } from 'react';
import { experiences } from './resume_configs';
import adobepdf from '../../assets/social/adobepdf.png';
import resumeFile from '../../assets/mstommes_resume.pdf';

const Experiences = (experiences) => {
    return (
        experiences.experiences.map((job, index) => {
            return (
                <div key={index}>
                    <h5>{job.title}</h5>
                    <ul>
                        {job.skills_list.map((skill, index) => {
                            return (
                                <li key={index}>{skill}</li>
                            )
                        })}
                    </ul>
                </div>
            )
        })
    )
}

class Resume extends Component {
    render() {
        return (
            <div>
                <div className="resumeContainer">
                    <div className="resumeLeft">
                        <div className="resumeContentHolder">
                            <section className="sectionTextMargins">
                                <h4 className="closerSpacingBottom">Maria Stommes</h4>
                                <p>Software Engineer</p>
                            </section>
                            <hr />

                            <section className="sectionTextMargins">
                                <h4 className="closerSpacingBottom">Personal Information:</h4>
                                <p className="closerSpacingBottom">Location: Minneapolis, MN</p>
                                <p className="closerSpacingTop"> Phone: 651-895-2525</p>
                            </section>
                            <hr />
                            <section className="sectionTextMargins">
                                <h4 className="closerSpacingBottom">Programming Experience</h4>
                                <p className="closerSpacingBottom">Javascript</p>
                                <p className="closerSpacingTop closerSpacingBottom">Typescript</p>
                                <p className="closerSpacingTop closerSpacingBottom">React</p>
                                <p className="closerSpacingTop closerSpacingBottom">Redux</p>
                                <p className="closerSpacingTop closerSpacingBottom">Next.js</p>
                                <p className="closerSpacingTop closerSpacingBottom">Node.js</p>
                                <p className="closerSpacingTop closerSpacingBottom">React Testing Library</p>

                                <p className="closerSpacingTop closerSpacingBottom">Java</p>
                                <p className="closerSpacingTop closerSpacingBottom">SpringBoot</p>
                                <p className="closerSpacingTop closerSpacingBottom">Spock</p>
                                <p className="closerSpacingTop closerSpacingBottom">MockWebServer</p>


                                <p className="closerSpacingTop closerSpacingBottom">Linux</p>
                                <p className="closerSpacingTop closerSpacingBottom">Docker</p>
                                <p className="closerSpacingTop closerSpacingBottom">Postgres</p>
                                <p className="closerSpacingTop closerSpacingBottom">Microservices</p>

                                <p className="closerSpacingTop closerSpacingBottom">API Development</p>
                                <p className="closerSpacingTop closerSpacingBottom">AWS</p>
                                <p className="closerSpacingTop closerSpacingBottom">Go (learning and ❤️)</p>
                            </section>
                        </div>
                    </div>
                    <div className="resumeRight">
                        <div className="resumeContentHolder">
                            <section>
                                <h5>Experience</h5>
                            </section>
                            <section>
                                <a href={resumeFile} download className="socialIcon" title="resumepdf"><img alt="resumepdfdownloadicon" src={adobepdf} />Resume PDF Download</a>
                            </section>
                            <Experiences experiences={experiences} />
                            <hr />
                            <section>
                                <h5>Education</h5>

                                <div>
                                    <ul>
                                        <li>Prime Digital Academy, Software Engineering Bootcamp</li>
                                        <li>University of St. Thomas, Certified Professional Project Management Certificate Program</li>
                                        <li>College of St. Scholastica,	Master of Education</li>
                                        <li>College of St. Benedict, Bachelor of Arts Communication</li>
                                    </ul>


                                </div>
                            </section>
                            <hr />
                            <section>
                                <h5>Conferences</h5>
                                <ul>
                                    <li>That Conference 2017</li>
                                    <li>MidwestJS 2018</li>
                                    <li>DevOps Days Mpls 2019, 2021</li>
                                    <li>UberConf 2020, 2021</li>
                                </ul>
                            </section>
                            <hr />
                            <section>
                                <h5>COMMUNITY</h5>
                                <ul>
                                    <li>Hack the Gap 2017 (2nd Place Team Finish)</li>
                                    <li>Hack the Gap 2019</li>
                                    <li>Habitat</li>
                                    <li>CSB Alumni Board Member</li>
                                    <li>Maryknoll China Serve</li>
                                    <li>Minnesota Literacy Council</li>
                                    <li>Habitat for Humanity</li>
                                    <li>Feed My Starving Children</li>
                                </ul>
                            </section>
                            <hr />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Resume;