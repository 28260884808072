import React, { Component } from 'react';
import { Footer } from '../../components/index.js'

class Home extends Component {
  render(){
    return (
     <div id='homepageBackground'>
       <p id='welcome'> WELCOME <br /> </p>
       <Footer />
     </div>
    );
  }
}

export default Home;
 