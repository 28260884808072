import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'


export const Navigation = () => {
    const location = useLocation();
    const path = location.pathname
    const linkStyle = path === "/" ?  "homeLink" : "regLink"

    return (
        <nav className="navigation">
            <li><Link className={linkStyle} to="/">HOME</Link></li>
            <li><Link className={linkStyle}  to="/resume">RESUME</Link></li>
            <li><a className={linkStyle}  href="mailto:mstommes@gmail.com?subject=Contact Sent From MStommes Website">CONTACT</a></li>
        </nav>
    )
}