const experience_list = [
    {
        title: "Senior Security & Privacy Software Engineer",
        company_year: "SmartThings (May 2022 - Present)",
        skills_list: [
            "Serve as primary technical lead for all information security software, including the platform privacy services",
            "Write readable, maintainable, well- documented, tested code",
            "Automate CI / CD build pipelines for information security team’s projects using GitHub actions",
            "Split feature work into manageable tasks by adding user stories to the Jira project backlog, which allows the team to work more collaboratively and efficiently",
            "Use Software Development Life Cycle(SDLC) best practices to plan, create, manage, and deploy software to public cloud infrastructure reducing risk and ensuring software quality",
            "Create and document a process for teams to use AWS secrets manager for SmartThings application secrets storage, reducing the risk that they can be compromised",
            "Recommend security best practices ensuring internal teams adhere to policy guidelines",
            "Collaborate across teams to coordinate releases and troubleshoot any software issues",
            "Build software support processes and documentation for the information security team’s products",
            "Translate internal and regulatory requirements into the design and development of SmartThings platform privacy services",
            "Create Typescript / Next.JS UI, with Okta authentication, for granular management of security findings",
            "Mentor less experienced developers by providing learning opportunities for professional growth",
            "Volunteer with the SmartThings Diversity, Equity, Inclusion, and Belonging(DEIBs) initiative"
        ]
    },
    {
        title: "Senior Software Engineer",
        company_year: "Target (July 2018 - May 2022)",
        skills_list: [
            "Wrote internal web UIs and APIs to interface with vendor-based application security scanning tools",
            "Built a front-end application for displaying and managing security findings using Spring Thymeleaf templates, Bootstrap & jQuery",
            "Designed, created, and tested badging API based on the Mozilla Open Badge API specification",
            "Participated in the Team Member Incubator Program, creating an API for determining store busyness",
            "Mentored and supported junior developers and interns",
            "Deployed applications using internal CI/CD pipeline tools",
            "Architected the hosting, deployment, and monitoring of on-premise Software Component Analysis (SCA) scanner using Docker, Docker Swarm, Telegraf, Grafana, Ansible, and CentOS"
        ]
    },
    {
        title: "Software Engineer",
        company_year: "Optum - United Health Care(January 2016 - July 2018)",
        skills_list: [
            "Built Java microservices using the Spring Cloud framework",
            "Wrote unit tests using Spock testing framework",
            "Built and deployed code using Jenkins pipelines",
            "Updated SQL queries and version control using Liquibase",
            "Created and updated user stories and task definitions using project management software",
            "Built and maintained web applications per the business needs and requirements",
            "Load - tested applications and analyzed performance analytics using JMeter",
            "Monitored API and UI performance using NewRelic",
            "Collaborated with development teams and leadership to create goals and objectives",
            "Created Amazon Web Services(AWS) Cloudfront distributions and managed static files in AWS S3",
            "Wrote new and maintained existing code using React, Typescript, and Javascript"
        ]
    },
    {
        title: "Software Engineering Student",
        company_year: "Prime Digital Academy (June 2015 - October 2015)",
        skills_list: [
            "Worked with software engineering classmates to successfully create a meeting dossier web application using NodeJS, Express, AngularJS and MongoDB (MEAN stack)",
            "Created Angular 1.5x applications using the MVC pattern",
            "Completed personal software (guitar chord image generator) project using the MEAN stack"
        ]
    },
    {
        title: "Adult Basic Education Instructor",
        company_year: "Minneapolis Public Schools (December 2010 - June 2015)",
        skills_list: [
            "Developed and taught ELL curriculum, including weekly lesson plans",
            "Taught critical thinking skills and created a supportive learning environment",
            "Assessed students’ progress with lesson and unit tests as well as CASAS and TABE tests",
            "Collaborated with co-teachers and fellow ABE staff and volunteers",
            "Wrote learning goals and objectives for increased student success",
            "Actively participated in staff development and training meetings"
        ]
    },
    {
        title: "Project Manager/Team Lead",
        company_year: "Data Recognition Corporation (2010-2014)",
        skills_list: [
            "Organized, scheduled, monitored, and completed simultaneous projects—which resulted in timely deliverables, cost savings, and client satisfaction",
            "Created markup and edited manual directions for state assessment coordinators",
            "Created and updated master projects and sub-schedules",
            "Implemented electronic review system, improving efficiency, and saving thousands of dollars",
            "Determined resource requirements to create test items for  software and print",
            "Eliminated redundancy by creating process flow diagrams",
            "Facilitated weekly meetings",
            "Trained and mentored co-workers and facilitated team-building activities"
        ]
    }
]


module.exports = {
    experiences: experience_list
}






