import React, { Component } from 'react';
import linkedin from '../../assets/social/linkedin.png';
import github from '../../assets/social/giticon.png';
import soundcloud from '../../assets/social/soundcloud.png';

class Footer extends Component{
  render(){
    let date = new Date();
    let currentYear = date.getFullYear();
    return (
    <div className="footer"> 
    <div>&copy; mstommes { currentYear }</div>
      <div> 
       <a href="https://www.linkedin.com/in/mstommes/" className="socialIcon" title="linkedin"><img alt="linkedinicon" src={linkedin} /></a> 
       <a href="https://github.com/mstommes" className="socialIcon" title="github"><img alt="githubicon" src={github} /></a>
       <a href="https://soundcloud.com/mariatommes" className="socialIcon" title="soundcloud"><img alt="soundcloudicon" src={soundcloud} /></a> 
      </div>
    </div>
    );
  }
}
export default Footer;
 